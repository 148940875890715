import { defineNuxtRouteMiddleware, navigateTo } from "#app";
import { ROUTES_NAME } from "~/config/router";
import { useAuthStore } from "~/stores/authStore";

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) return;
  const authStore: any = useAuthStore();
  const tokenCookie = useCustomCookie("token");
  if (tokenCookie.value) {
    //check user info exists
    if (authStore.user.is_completed) {
      return navigateTo({ path: ROUTES_NAME.LOBBY, replace: true });
    }
  } else {
    return navigateTo({ path: ROUTES_NAME.LOGIN, replace: true });
  }
});
